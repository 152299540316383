<template><p /></template>
<script>
/* eslint-disable global-require */

// eslint-disable-next-line import/no-cycle
import { getHomeRouteForLoggedInUser } from '@/auth/utils'
// eslint-disable-next-line import/no-cycle
import useJwt from '@/auth/jwt/useJwt'

export default {
  beforeCreate() {
    const appLoading = document.getElementById('loading-bg')
    if (appLoading) {
      appLoading.style.display = ''
    }
    const query = { ...this.$route.query }
    this.$router.replace({ query: null })
    if (query && Object.keys(query).includes('code')) {
      useJwt.loginKeyCloak(query.code).then(res => {
        useJwt.afterLogin(this, res)
      }).catch(err => {
        this.$router.replace({ name: 'auth-login' }).then(() => {
          this.errorNotification(this, err)
        })
      })
    } else {
      this.$router.replace(getHomeRouteForLoggedInUser())
    }
  },
}
</script>
